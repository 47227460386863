import React from 'react';
import './../alignPages.css';
import './shop.css';
/**
 * 
 * @returns NOT USED, STRETCH FEATURE
 */
const Shop = () => {
    return (
        <>
            <div className="wrapper">
                <div className="pageHeight">

                    <div className="aHeader">
                        <h2>FrogShop</h2>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Shop;