import React from 'react';
import './../alignPages.css'
import './settings.css'

/**
 * 
 * @returns NOT USED, STRETCH FEATURE
 */
const Settings = () => {
    return (
        <>
            <div className="wrapper">
                <div className="pageHeight"
                    style={{
                        color: 'white',
                    }}>
                    <h3>
                        This is where you'll go to change stuff! Ominous.... :0
                    </h3>
                </div>
            </div>
        </>
    );
};

export default Settings;